<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item"><router-link-active :to="{ name: 'partners' }">Partners</router-link-active></li>
        </ol>
      </nav>
    </div>
    <PartnerComponent />
  </div>
</template>
<script>
import PartnerComponent from "@/components/Partners/PartnerComponent";

export default {
  components: { PartnerComponent },
};
</script>
<style></style>
