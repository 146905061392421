var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive"},[(_vm.rows)?_c('vue-good-table',{ref:"data-table",attrs:{"columns":this.columns,"rows":this.rows,"total-rows":this.rows.length,"search-options":{
      enabled: true,
    },"compactMode":"","pagination-options":{
      enabled: true,
      perPage: 100,
    },"sort-options":{
      enabled: true,
    },"select-options":{ enabled: this.selectOptions }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'view')?_c('span',[_c('router-link',{attrs:{"to":{ name: _vm.detailsRouteName, params: { id: props.row.id } }}},[_c('button',{staticClass:"btn btn-primary btn-sm"},[_vm._v("View")])])],1):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))]),(props.column.field === 'functional')?_c('span',[(props.row.functional)?_c('span',{staticClass:"badge badge-success"},[_vm._v("Functional")]):_c('span',{staticClass:"badge badge-warning"},[_vm._v("Damaged")])]):_vm._e(),(
          props.column.field === 'active' ||
          props.column.field === 'is_active'
        )?_c('span',[(props.row.active || props.row.is_active)?_c('span',{staticClass:"badge badge-success"},[_vm._v("Active")]):_c('span',{staticClass:"badge badge-warning"},[_vm._v("Inactive")])]):_vm._e()]}}],null,false,3094873133)},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[(
          _vm.activeTab &&
          (_vm.activeTab.includes('to be Deployed') ||
            _vm.activeTab.includes('All')) &&
          !_vm.isHidden
        )?_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.getSelectedRecords}},[_vm._v(" Transfer to Partner ")]):_vm._e()]),_c('template',{slot:"pagination-bottom"},[(_vm.numOfPages > 1)?_c('div',{staticClass:"overflow-auto mt-4"},[_c('b-pagination-nav',{attrs:{"base-url":_vm.basePaginatorUrl,"link-gen":_vm.linkGen,"total-rows":_vm.paginationRows,"per-page":_vm.perPage,"number-of-pages":_vm.numOfPages,"align":"center","first-number":"","last-number":""},on:{"change":_vm.pageClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("No records found.")])],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }