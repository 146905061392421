var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 py-4"},[_c('ul',{staticClass:"nav nav-tabs nav-justified"},[_c('li',{staticClass:"nav-item"},[_c('span',{class:['nav-link', _vm.activeTab === 'all' && 'active'],on:{"click":() => _vm.handleSetActiveTab('all')}},[_vm._v(" All ")])]),_c('li',{staticClass:"nav-item"},[_c('span',{class:['nav-link', _vm.activeTab === 'active' && 'active'],on:{"click":() => _vm.handleSetActiveTab('active')}},[_vm._v(" Active ")])]),_c('li',{staticClass:"nav-item"},[_c('span',{class:['nav-link', _vm.activeTab === 'suspended' && 'active'],on:{"click":() => _vm.handleSetActiveTab('suspended')}},[_vm._v(" Suspended ")])])]),(_vm.loading)?_c('div',{staticClass:"px-3 py-6"},[_c('table-skeleton')],1):_c('DataTable',{attrs:{"columns":[
      { field: 'name', label: 'Name' },
      { field: 'country', label: 'Country' },
      { field: 'admin_email', label: 'Admin Email' },
      { field: 'plan.name', label: 'Plan', formatFn: _vm.planFormater },
      {
        field: 'is_active',
        label: 'Status',
        formatFn: _vm.displayEmptyValue,
      },
      { field: 'created', label: 'Added', formatFn: _vm.createdFormatter },
      { field: 'view', label: 'Action', sortable: false },
    ],"rows":_vm.partners,"totalRecords":_vm.totalPartners,"basePaginatorUrl":_vm.basePartnerUrl,"detailsRouteName":"partner-details"},on:{"fetch-next-page-results":_vm.getPartners}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }