<template>
  <div class="col-12 py-4">
    <ul class="nav nav-tabs nav-justified">
      <li class="nav-item">
        <span
          :class="['nav-link', activeTab === 'all' && 'active']"
          @click="() => handleSetActiveTab('all')"
        >
          All
        </span>
      </li>
      <li class="nav-item">
        <span
          :class="['nav-link', activeTab === 'active' && 'active']"
          @click="() => handleSetActiveTab('active')"
        >
          Active
        </span>
      </li>
      <li class="nav-item">
        <span
          :class="['nav-link', activeTab === 'suspended' && 'active']"
          @click="() => handleSetActiveTab('suspended')"
        >
          Suspended
        </span>
      </li>
    </ul>

    <div class="px-3 py-6" v-if="loading">
      <table-skeleton> </table-skeleton>
    </div>

    <DataTable
      v-else
      :columns="[
        { field: 'name', label: 'Name' },
        { field: 'country', label: 'Country' },
        { field: 'admin_email', label: 'Admin Email' },
        { field: 'plan.name', label: 'Plan', formatFn: planFormater },
        {
          field: 'is_active',
          label: 'Status',
          formatFn: displayEmptyValue,
        },
        { field: 'created', label: 'Added', formatFn: createdFormatter },
        { field: 'view', label: 'Action', sortable: false },
      ]"
      :rows="partners"
      :totalRecords="totalPartners"
      :basePaginatorUrl="basePartnerUrl"
      detailsRouteName="partner-details"
      @fetch-next-page-results="getPartners"
    />
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "@/components/DataTable";

export default {
  name: "PartnersTable",
  components: { DataTable },
  data() {
    return {
      partners: null,
      totalPartners: null,
      basePartnerUrl: "",
      error: "",
      activeTab: "all",
      loading: true,
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    getPartners(clearPartners, basePartnerUrl = `v1/partners/?plan=`) {
      this.basePartnerUrl = basePartnerUrl;
      if (clearPartners) {
        this.partners = [];
      }
      axios
        .get(basePartnerUrl)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.partners = response.data.results;
            this.totalPartners = response.data.count;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    handleSetActiveTab(tab) {
      this.activeTab = tab;
      if (tab === "all") {
        this.getPartners(true);
      } else if (tab === "active") {
        this.getPartners(true, "v1/partners/?is_active=true");
      } else if (tab === "suspended") {
        this.getPartners(true, "v1/partners/?is_active=false");
      }
    },
    displayEmptyValue(value) {
      return value ? "" : "";
    },
    createdFormatter(value) {
      return new Date(value).toLocaleString();
    },
    planFormater(value) {
      return this.$options.filters.planNameDisplay(value);
    },
  },
};
</script>
