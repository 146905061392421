<template>
  <div>
    <div v-if="isHidden" class="row">
      <div class="col-12">
        <div class="card card-border-color card-border-color-secondary">
          <div class="card-header card-header-divider">
            Add Partner
            <div class="btn btn-primary float-right" @click="toggleIsHidden">
              Cancel
            </div>
          </div>
          <div class="card-body">
            <PartnerCreationForm @toggle-is-hidden="toggleIsHidden" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isHidden" class="row">
      <div class="col-sm-12">
        <div class="card card-table">
          <div class="card-header">
            All Partners
            <div
              class="btn btn-primary float-right"
              @click="toggleIsHidden"
              v-if="loggedInUser.role === adminRole"
            >
              Add Partner
            </div>
          </div>
          <div class="card-body">
            <PartnersTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import PartnerCreationForm from "@/components/Partners/CreationForm";
import PartnersTable from "@/components/Partners/Table";

export default {
  name: "PlanComponent",
  components: { PartnerCreationForm, PartnersTable },
  data() {
    return {
      isHidden: false,
      editMode: false,
      adminRole: "ared_admin",
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  methods: {
    toggleIsHidden() {
      this.isHidden = !this.isHidden;
    },
  },
};
</script>
<style></style>
