<template>
  <div class="table-responsive">
    <vue-good-table
      ref="data-table"
      :columns="this.columns"
      :rows="this.rows"
      :total-rows="this.rows.length"
      :search-options="{
        enabled: true,
      }"
      compactMode
      v-if="rows"
      :pagination-options="{
        enabled: true,
        perPage: 100,
      }"
      :sort-options="{
        enabled: true,
      }"
      :select-options="{ enabled: this.selectOptions }"
    >
      <div slot="selected-row-actions">
        <button
          v-if="
            activeTab &&
            (activeTab.includes('to be Deployed') ||
              activeTab.includes('All')) &&
            !isHidden
          "
          @click="getSelectedRecords"
          class="btn btn-primary"
        >
          Transfer to Partner
        </button>
        <!-- <button
          v-if="activeTab == 'transit'"
          @click="updateDeliveryStatus"
          class="btn btn-primary"
        >
          Mark as Delivered
        </button> -->
      </div>
      <template slot="pagination-bottom">
        <div class="overflow-auto mt-4" v-if="numOfPages > 1">
          <b-pagination-nav
            v-model="currentPage"
            :base-url="basePaginatorUrl"
            :link-gen="linkGen"
            :total-rows="paginationRows"
            :per-page="perPage"
            :number-of-pages="numOfPages"
            align="center"
            first-number
            last-number
            @change="pageClick"
          ></b-pagination-nav>
        </div>
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'view'">
          <router-link
            :to="{ name: detailsRouteName, params: { id: props.row.id } }"
          >
            <button class="btn btn-primary btn-sm">View</button>
          </router-link>
        </span>
        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        <span v-if="props.column.field === 'functional'">
          <span v-if="props.row.functional" class="badge badge-success"
            >Functional</span
          >
          <span v-else class="badge badge-warning">Damaged</span>
        </span>
        <span
          v-if="
            props.column.field === 'active' ||
            props.column.field === 'is_active'
          "
        >
          <span
            v-if="props.row.active || props.row.is_active"
            class="badge badge-success"
            >Active</span
          >
          <span v-else class="badge badge-warning">Inactive</span>
        </span>
      </template>
      <div slot="emptystate">No records found.</div>
    </vue-good-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DataTable",
  props: {
    columns: Array,
    rows: Array,
    selectOptions: Boolean,
    detailsRouteName: String,
    activeTab: String,
    isHidden: Boolean,
    basePaginatorUrl: String,
    totalRecords: Number,
    perPage: {
      type: Number,
      default() {
        return 100;
      },
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    paginationRows() {
      return this.totalRecords;
    },
    numOfPages() {
      var pageCalculator = Math.ceil(this.paginationRows / this.perPage);
      return pageCalculator;
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    linkGen() {
      return {
        link: null,
      };
    },
    pageClick(page) {
      this.currentPage = page;
      this.$emit(
        "fetch-next-page-results",
        "true",
        `${this.basePaginatorUrl}&page=${page}`
      );
    },
    getSelectedRecords() {
      const selectedRecords = this.$refs["data-table"].selectedRows;
      this.$emit("toggle-is-hidden");
      this.$emit("get-selected-rows", selectedRecords);
    },
    updateDeliveryStatus() {
      const selectedRows = this.$refs["data-table"].selectedRows;
      if (this.activeTab == "transit") {
        if (this.currentRouteName === "routers") {
          this.updateRouters(selectedRows, "Delivered");
        } else {
          this.updateKiosks(selectedRows, "Delivered");
        }
      }
    },
    async updateRouters(routers, location) {
      const updateRouterLocationUrl = "v1/routers/update-location-status/";
      await axios
        .post(updateRouterLocationUrl, { routers, location })
        .then((response) => {
          if (response.status === 200) {
            this.$emit("get-routers", true);
            this.$swal("", `${response.data.detail}`, "success");
            this.$emit("set-active-tab", "unassigned");
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            this.$swal("Error", `${error.response.data.detail}`, "warning");
          } else {
            this.$swal("Error", "Could not update routers", "warning");
          }
        });
    },
    async updateKiosks(kiosks, location) {
      const updateKioskLocationUrl = "v1/kiosks/update-location-status/";
      await axios
        .post(updateKioskLocationUrl, { kiosks, location })
        .then((response) => {
          if (response.status === 200) {
            this.$emit("get-kiosks", true);
            this.$swal("", "Kiosks Updated successfully", "success");
            this.$emit("set-active-tab", "unassigned");
          }
        })
        .catch((error) => {
          if (error.response.data.detail) {
            this.$swal("Error", `${error.response.data.detail}`, "warning");
          } else {
            this.$swal("Error", "Could not update kiosks", "warning");
          }
        });
    },
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #fff !important;
  border-color: var(--ared-green) !important;
}
.page-link {
  color: var(--ared-green) !important;
}
</style>
